var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tag === "h1"
    ? _c("h1", { class: _vm.computedClass }, [_vm._t("default")], 2)
    : _vm.tag === "h2"
    ? _c("h2", { class: _vm.computedClass }, [_vm._t("default")], 2)
    : _vm.tag === "h3"
    ? _c("h3", { class: _vm.computedClass }, [_vm._t("default")], 2)
    : _vm.tag === "h4"
    ? _c("h4", { class: _vm.computedClass }, [_vm._t("default")], 2)
    : _vm.tag === "h5"
    ? _c("h5", { class: _vm.computedClass }, [_vm._t("default")], 2)
    : _vm.tag === "h6"
    ? _c("h6", { class: _vm.computedClass }, [_vm._t("default")], 2)
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }